import _mock from '../_mock';

// ----------------------------------------------------------------------

const TITLE = [
  `The A - Z Of Event`,
  `Believing These 7 Myths About Event Keeps You From Growing`,
  `Don't Waste Time! 7 Facts Until You Reach Your Event`,
  `How 7 Things Will Change The Way You Approach Event`,
  `Event Doesn't Have To Be Hard. Read These 7 Tips`,
  `The 7 Biggest Event Mistakes You Can Easily Avoid`,
  `Best 30 Tips For Event`,
  `Apply These 7 Secret Techniques To Improve Event`,
  `Best Event Android Apps`,
  `Best Event Tips You Will Read This Year`,
  `Top 7 Lessons About Event To Learn Before You Hit 30`,
  `How To Make More Event By Doing Less`,
];

const content = (name: string) => `
<p>Yayasan Widya Ssentana, sebuah yayasan yang bergerak di bidang pendidikan dan kebudayaan, akan segera meluncurkan website jurnal Bhakti Sastra. Jurnal ini bertujuan untuk menampung hasil penelitian dan kajian di bidang bahasa dan sastra, khususnya yang berkaitan dengan budaya Bali.

Jurnal Bhakti Sastra terbit dua kali setahun, yaitu pada bulan Juni dan Desember. Setiap edisi jurnal berisi artikel-artikel hasil penelitian dan kajian dari berbagai perguruan tinggi, lembaga penelitian, dan praktisi di bidang bahasa dan sastra.</p><p>

Artikel-artikel yang diterbitkan dalam jurnal Bhakti Sastra telah melalui proses seleksi yang ketat oleh para ahli di bidang bahasa dan sastra. Artikel-artikel tersebut juga telah melalui proses peer review oleh para akademisi dari berbagai perguruan tinggi di Indonesia.

Website ini akan menyediakan akses gratis bagi masyarakat untuk membaca artikel-artikel yang diterbitkan dalam jurnal Bhakti Sastra.

</p><p>Saat ini, website jurnal Bhakti Sastra masih dalam tahap pengembangan. Yayasan Widya Sentana mengundang para akademisi, peneliti, dan praktisi untuk memberikan masukan dan saran untuk pengembangan website ini.

Masukan dan saran dapat dikirimkan melalui email ke [alamat email].

Kami berharap website jurnal Bhakti Sastra dapat menjadi wadah bagi para akademisi, peneliti, dan praktisi untuk mempublikasikan hasil penelitian dan kajiannya, serta dapat menjadi sumber informasi yang bermanfaat bagi masyarakat umum.</p>

`;

const base = (index: number) => ({
  id: _mock.id(index),
  title: TITLE[index],
  description: _mock.text.description(index),
  category: 'Marketing',
  favorited: _mock.boolean(index),
  createdAt: _mock.time(index),
  duration: '8 minutes read',
  tags: [
    { label: 'Marketing', path: '' },
    { label: 'Development', path: '' },
    { label: 'HR & Recruiting', path: '' },
    { label: 'Design', path: '' },
    { label: 'Management', path: '' },
  ],
  author: {
    name: _mock.name.fullName(index),
    role: _mock.role(index),
    picture: _mock.image.avatar(index),
    quotes: 'Member since Mar 15, 2021',
    about:
      'Integer tincidunt. Nullam dictum felis eu pede mollis pretium. Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem.',
  },
});

// ----------------------------------------------------------------------

export const _blogMarketingPosts = [...Array(12)].map((_, index) => ({
  ...base(index),
  content: content('marketing'),
  coverImg: _mock.image.marketing(index),
  heroImg: `/assets/images/marketing/marketing_post_hero.jpg`,
}));

export const _blogTravelPosts = [...Array(12)].map((_, index) => ({
  ...base(index),
  content: content('travel'),
  coverImg: _mock.image.travel(index),
  heroImg: `/assets/images/travel/travel_post_hero.jpg`,
}));

export const _blogCareerPosts = [...Array(12)].map((_, index) => ({
  ...base(index),
  content: content('career'),
  coverImg: _mock.image.career(index),
  heroImg: `/assets/images/career/career_post_hero.jpg`,
}));

export const _blogCoursePosts = [...Array(12)].map((_, index) => ({
  ...base(index),
  content: content('course'),
  coverImg: _mock.image.course(index),
  heroImg: `/assets/images/course/course_post_hero.jpg`,
}));
