// routes
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

// export const pageLinks = [
//   {
//     order: '1',
//     subheader: 'Marketing',
//     cover: '/assets/images/menu/menu_marketing.jpg',
//     items: [
//       { title: 'Landing', path: paths.marketing.landing },
//       { title: 'Services', path: paths.marketing.services },
//       { title: 'Case Studies', path: paths.marketing.caseStudies },
//       { title: 'Case Study', path: paths.marketing.caseStudy },
//       { title: 'Blog Posts', path: paths.marketing.posts },
//       { title: 'Blog Post', path: paths.marketing.post },
//       { title: 'About', path: paths.marketing.about },
//       { title: 'Contact', path: paths.marketing.contact },
//     ],
//   },
//   {
//     order: '6',
//     subheader: 'Travel',
//     cover: '/assets/images/menu/menu_travel.jpg',
//     items: [
//       { title: 'Landing', path: paths.travel.landing },
//       { title: 'Tours', path: paths.travel.tours },
//       { title: 'Tour', path: paths.travel.tour },
//       { title: 'Checkout', path: paths.travel.checkout },
//       { title: 'Order Completed', path: paths.travel.orderCompleted },
//       { title: 'Blog Posts', path: paths.travel.posts },
//       { title: 'Blog Post', path: paths.travel.post },
//       { title: 'About', path: paths.travel.about },
//       { title: 'Contact', path: paths.travel.contact },
//     ],
//   },
//   {
//     order: '2',
//     subheader: 'Career',
//     cover: '/assets/images/menu/menu_career.jpg',
//     items: [
//       { title: 'Landing', path: paths.components.root },
//       { title: 'Jobs', path: paths.components.jobs },
//       { title: 'Job', path: paths.components.job },
//       { title: 'Blog Posts', path: paths.components.posts },
//       { title: 'Blog Post', path: paths.components.post },
//       { title: 'About', path: paths.components.about },
//       { title: 'Contact', path: paths.components.contact },
//     ],
//   },
//   {
//     order: '5',
//     subheader: 'E-learning',
//     cover: '/assets/images/menu/menu_elearning.jpg',
//     items: [
//       { title: 'Landing', path: paths.eLearning.landing },
//       { title: 'Courses', path: paths.eLearning.courses },
//       { title: 'Course', path: paths.eLearning.course },
//       { title: 'Blog Posts', path: paths.eLearning.posts },
//       { title: 'Blog Post', path: paths.eLearning.post },
//       { title: 'About', path: paths.eLearning.about },
//       { title: 'Contact', path: paths.eLearning.contact },
//     ],
//   },
//   {
//     isNew: true,
//     order: '3',
//     subheader: 'E-commerce',
//     cover: '/assets/images/menu/menu_ecommerce.jpg',
//     items: [
//       { title: 'Landing', path: paths.eCommerce.landing },
//       { title: 'Products', path: paths.eCommerce.products },
//       { title: 'Product', path: paths.eCommerce.product },
//       { title: 'Cart', path: paths.eCommerce.cart },
//       { title: 'Checkout', path: paths.eCommerce.checkout },
//       { title: 'Order Completed', path: paths.eCommerce.orderCompleted },
//       { title: 'Wishlist', path: paths.eCommerce.wishlist },
//       { title: 'Compare', path: paths.eCommerce.compare },
//       { title: 'Account Personal', path: paths.eCommerce.account.personal },
//       { title: 'Account Wishlist', path: paths.eCommerce.account.wishlist },
//       { title: 'Account Vouchers', path: paths.eCommerce.account.vouchers },
//       { title: 'Account Orders', path: paths.eCommerce.account.orders },
//       { title: 'Account Payment', path: paths.eCommerce.account.payment },
//     ],
//   },
//   {
//     order: '4',
//     subheader: 'Common',
//     items: [
//       { title: 'Login Cover', path: paths.loginCover },
//       { title: 'Login Illustration', path: paths.loginIllustration },
//       { title: 'Login Background', path: paths.loginBackground },
//       { title: 'Register Cover', path: paths.registerCover },
//       { title: 'Register Illustration', path: paths.registerIllustration },
//       { title: 'Register Background', path: paths.registerBackground },
//       { title: 'Reset Password', path: paths.resetPassword },
//       { title: 'Verify Code', path: paths.verifyCode },
//       { title: '404 Error', path: paths.page404 },
//       { title: '500 Error', path: paths.page500 },
//       { title: 'Maintenance', path: paths.maintenance },
//       { title: 'ComingSoon', path: paths.comingsoon },
//       { title: 'Pricing 01', path: paths.pricing01 },
//       { title: 'Pricing 02', path: paths.pricing02 },
//       { title: 'Payment', path: paths.payment },
//       { title: 'Support', path: paths.support },
//     ],
//   },
// ];

export const navConfig = [
  { title: 'Beranda', path: '/' },
  // { title: 'Components', path: paths.components.root },
  // {
  //   title: 'Pages',
  //   path: paths.pages,
  //   children: [pageLinks[0], pageLinks[1], pageLinks[2], pageLinks[3], pageLinks[4], pageLinks[5]],
  // },
  { title: 'Jurnal', path: paths.components.category },
  { title: 'Pengumuman', path: paths.components.announcement },
  { title: 'Dewan Redaksi', path: paths.components.editorial },
  { title: 'Alamat Redaksi', path: paths.components.address },
  { title: 'Tentang', path: paths.components.about },
  { title: 'Petunjuk Penulisan', path: paths.components.instructions },


  // { title: 'Structure', path: paths.components.jobs },
];
